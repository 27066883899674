import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import slugify from "slugify";
import ReactSlider from "react-slider";
import { addItem } from "../utils/addtocart";
import ReactPaginate from "react-paginate";
import { Toaster, toast } from "sonner";
import PreLoader from "./PreLoader";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { Helmet } from "react-helmet";
import banner from "../assets/image/banner.webp";
import banner_watch from "../assets/image/watch_banner.webp";
import banner_bag from "../assets/image/bag_banner.webp";
import headphone from "../assets/image/headphone.webp" ;
import LazyLoad from "react-lazy-load";

const Shop = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { parentId } = useParams();

  const [grid, setGrid] = useState("3");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // RANGE
  const [values, setValues] = useState([0, 0]);
  const [maxValue, setMaxValue] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const [filteredData, setFilteredData] = useState([]);
  const uniqueParents = new Set();
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [bannerCat, setBannerCat] = useState([]);

  const [selectShow, setSelectShow] = useState(false);

  const [backgroundUrl, setBackgroundUrl] = useState("");

  const [sentencesHtml, setSentencesHtml] = useState("");

  const handleModalDesc = (i) => {
    setModalDesc(!modalDesc);
    setSelectedData(i);
  };

  const handleGrid = (i) => {
    setGrid(i);
  };

  const handleOpen = (i) => {
    setOpen(!open);
  };

  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=vertu")
      .then((res) => {
        const productData = res.data;
        setData(productData);
        const maxPrice = Math.max(...productData.map((item) => item.price));
        setMaxValue(maxPrice);
        setValues([0, maxPrice]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setGrid(1);
        setSelectShow(true);
      } else {
        setGrid(3);
        setSelectShow(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (selectedData && selectedData.id) {
      axios
        .get(`https://myshops.az/apk/app_view.php?id=${selectedData.id}`)
        .then((res) => {
          const detailsData = res.data.pictures;
          setImages(detailsData);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [selectedData]);

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleChange = (val) => {
    setValues(val);
  };

  useEffect(() => {
    let filteredData = data.filter((item) => {
      let priceFilter =
        values !== null
          ? item.price >= values[0] && item.price <= values[1]
          : item > 0;
      return priceFilter;
    });
    setFilteredData(filteredData);
  }, [data, values]);

  const handleSelect = (e) => {
    const selected = e.target.value;

    let sortedData = [...data];

    if (selected === "asc") {
      sortedData.sort((a, b) => a.price - b.price);
    } else if (selected === "desc") {
      sortedData.sort((a, b) => b.price - a.price);
    } else if (selected === "az") {
      sortedData.sort((a, b) => a.name.localeCompare(b.name));
    } else if (selected === "za") {
      sortedData.sort((a, b) => b.name.localeCompare(a.name));
    }
    if (selectedParentId) {
      const parentFilteredData = sortedData.filter(
        (item) => item.parent === selectedParentId
      );
      setFilteredData(parentFilteredData);
    } else {
      setFilteredData(sortedData);
    }
  };

  const ITEMS_PER_PAGE = 15;
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentPageData = filteredData.slice(offset, offset + ITEMS_PER_PAGE);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    window.scrollTo({ top: 100 });
  };
  const [cat, setCat] = useState([]);
  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_cat.php")
      .then((res) => {
        const data = res.data;
        setCat(data);
      })
      .catch((err) => {
        console.log(err);
      });
      if (selectedData) {
        const description = language == "en" ? selectedData.description_ru : selectedData.description_az;
        const sentences = description &&  description.split(/(?<=\.)/).filter(sentence => sentence.trim() !== '');
        const numberOfSentencesToShow = 4; 
        const selectedSentences = sentences && sentences.slice(0, numberOfSentencesToShow);
        const sentencesHtml = selectedSentences && selectedSentences.join(' ');
        setSentencesHtml(sentencesHtml);
      }
  }, [language,selectedData]);

  useEffect(() => {
    if (parentId) {
      const parentFindParam = cat.find(
        (i) => slugify(i.name_az).toLowerCase() == parentId
      );
      setBannerCat(parentFindParam);
      if (parentFindParam) {
        const paramsFindId = data.filter(
          (item) => item.parent === parentFindParam.id
        );
        setFilteredData(paramsFindId);
      }
    }
    let image;
    if (bannerCat && bannerCat.name_az) {
      if (bannerCat.name_az == "Ağıllı saat") {
        image = banner_watch;
        setBackgroundUrl(image);
      } else if (bannerCat.name_az == "Premial Vertu aksesuarları") {
        image = banner_bag;
        setBackgroundUrl(image);
      }
      else if (bannerCat.name_az == "Qulaqlıq") {
        image = headphone;
        setBackgroundUrl(image);
      }
    } else {
      image = banner;
      setBackgroundUrl(image);
    }
  }, [parentId, data, cat, bannerCat]);

  const findData = (parent) => {
    const findProduct = data.filter((item) => item.parent === parent.id);
    setFilteredData(findProduct);
  };

  const handleSelectParent = (parentId) => {
    setSelectedParentId(parentId);
    findData({ id: parentId });
    if (parentId == "all") {
      setFilteredData(data);
    }
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
 


  return (
    <>
      <Helmet>
        <title>{t.collections}</title>
      </Helmet>

      <div className="banner" style={{ background: `url(${backgroundUrl})` }}>
        <div className="overlay_banner">
          <div className="overlay_text d-flex justify-content-center align-items-center h-100">
            <h5>
              {parentId
                ? language === "az"
                  ? bannerCat && bannerCat.name_az
                  : bannerCat && bannerCat.name_ru
                : t.collections}
            </h5>
          </div>
        </div>
      </div>

      {loading ? (
        <PreLoader />
      ) : (
        <>
          <section className="flat-spacing-1">
            <div className="container">
              <div className="tf-shop-control grid-3 align-items-center">
                <div className="tf-control-filter">
                  {/* <a href="#filterShop" data-bs-toggle="offcanvas" aria-controls=  "offcanvasLeft" class="tf-btn-filter"><span class="icon icon-filter"></span><span class="text">Filter</span></a> */}
                </div>
                <ul className="tf-control-layout d-flex justify-content-center">
                  <li
                    className={`tf-view-layout-switch sw-layout-2 ${
                      grid == "2" ? "active" : ""
                    }`}
                    data-value-grid="grid-2"
                    style={{ display: `${grid == 1 ? "none" : ""}` }}
                  >
                    <div className="item">
                      <span
                        className="icon icon-grid-2"
                        onClick={() => handleGrid("2")}
                      />
                    </div>
                  </li>
                  <li
                    className={`tf-view-layout-switch sw-layout-3 1 ${
                      grid == "3" ? "active" : ""
                    }`}
                    data-value-grid="grid-3"
                  >
                    <div className="item">
                      <span
                        className="icon icon-grid-3"
                        onClick={() => handleGrid("3")}
                      />
                    </div>
                  </li>
                  <li
                    className={`tf-view-layout-switch sw-layout-4 ${
                      grid == "4" ? "active" : ""
                    }`}
                    data-value-grid="grid-4"
                  >
                    <div className="item">
                      <span
                        className="icon icon-grid-4"
                        onClick={() => handleGrid("4")}
                      />
                    </div>
                  </li>
                </ul>
                <div className="tf-control-sorting d-flex justify-content-end">
                  <div style={{ marginTop: "5px" }}>
                    {selectShow && (
                      <select
                        id="selectFilter"
                        value={selectedParentId}
                        onChange={(e) => handleSelectParent(e.target.value)}
                        className={`${selectShow ? "d-block" : "d-none"}`}
                      >
                        <option value="all aboutDesc">{t.all}</option>
                        {data.map((dataId) => {
                          const findCat = cat.find(
                            (catId) => catId.id == dataId.category
                          );
                          if (findCat) {
                            const findParent = cat.find(
                              (catId) => catId.id == findCat.parent
                            );
                            if (
                              findParent &&
                              !uniqueParents.has(findParent.id)
                            ) {
                              uniqueParents.add(findParent.id);
                              return (
                                <option
                                  key={findParent.id}
                                  value={findParent.id}
                                >
                                  {language === "az"
                                    ? findParent.name_az
                                    : findParent.name_ru}
                                </option>
                              );
                            }
                          }
                        })}
                      </select>
                    )}
                  </div>
                  <div
                    className="tf-dropdown-sort"
                    data-bs-toggle="dropdown"
                    style={{ border: "none" }}
                  >
                    <select
                      name=""
                      id="sortSelect"
                      onChange={handleSelect}
                      className="aboutDesc"
                    >
                      <option value="" disabled selected>
                        {t.sort} :
                      </option>
                      <option value="az">{t.sort1}</option>
                      <option value="za">{t.sort2}</option>
                      <option value="asc">{t.sort4}</option>
                      <option value="desc">{t.sort3}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="tf-row-flex">
                <aside className="tf-shop-sidebar wrap-sidebar-mobile">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="aboutDesc">
                      {t.min} : {values[0]} ₼
                    </label>
                    <label className="aboutDesc">
                      {t.max} : {values[1]} ₼
                    </label>
                  </div>
                  <div className="py-3">
                    <ReactSlider
                      className="rangeslider"
                      onChange={handleChange}
                      min={0}
                      max={maxValue}
                      value={values}
                      pearling
                      minDistance={10}
                    />
                  </div>
                  <div className="widget-facet wd-categories">
                    <div
                      className={`facet-title ${open ? "collapsed" : ""}`}
                      data-bs-target="#categories"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      aria-controls="categories"
                      onClick={() => handleOpen()}
                    >
                      <span className="aboutDesc">{t.categories}</span>
                      <span className="icon icon-arrow-up" />
                    </div>
                    <div
                      id="categories"
                      className={`collapse ${open ? "" : "show"}`}
                    >
                      <ul className="list-categoris current-scrollbar mb_36">
                        <li
                          style={{ cursor: "pointer" }}
                          className={`cate-item aboutDesc ${
                            filteredData.length === data.length ? "active" : ""
                          }`}
                          onClick={() => {
                            setFilteredData(data);
                            setSelectedParentId(null);
                          }}
                        >
                          {t.all}
                        </li>
                        {data.map((dataId) => {
                          const findCat = cat.find(
                            (catId) => catId.id == dataId.category
                          );
                          if (findCat) {
                            const findParent = cat.find(
                              (catId) => catId.id == findCat.parent
                            );

                            if (findParent) {
                              if (!uniqueParents.has(findParent.id)) {
                                uniqueParents.add(findParent.id);
                                return (
                                  <li
                                    key={findParent.id}
                                    className={`cate-item aboutDesc ${
                                      selectedParentId === findParent.id
                                        ? "active"
                                        : ""
                                    } current`}
                                  >
                                    <span
                                      onClick={() =>
                                        handleSelectParent(findParent.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="aboutDesc"
                                    >
                                      {language === "az"
                                        ? findParent.name_az
                                        : findParent.name_ru}
                                    </span>
                                    &nbsp;<span> </span>
                                  </li>
                                );
                              }
                            }
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  </div>
                </aside>
                <div className="tf-shop-content">
                  {/* <p>
                    {filteredData.length} nəticədən 1-{ITEMS_PER_PAGE} arası
                    göstərilir
                  </p> */}
                  <div
                    className="grid-layout wrapper-shop"
                    data-grid={`grid-${grid}`}
                  >
                    {currentPageData.length === 0 ? (
                      <h6 className="text-center">{t.noresult}</h6>
                    ) : (
                      <>
                        {currentPageData.map((i) => (
                          <div className="card-product">
                            <div className="card-product-wrapper">
                              <NavLink
                                to={`/collections/${slugify(
                                  `${i.name_show}-${i.memory + "GB"}-${i.value}`
                                ).toLowerCase()}`}
                                className="product-img"
                              >
                                <LazyLoad>
                                  <img
                                    className="lazyload img-product aboutDesc"
                                    data-src={i.icon}
                                    src={i.icon}
                                    alt="image-product"
                                  />
                                </LazyLoad>
                                <LazyLoad>
                                  <img
                                    className="lazyload img-hover aboutDesc"
                                    data-src={i.icon}
                                    src={i.icon}
                                    alt="image-product"
                                  />
                                </LazyLoad>
                              </NavLink>
                              <div className="list-product-btn absolute-2">
                                <a
                                  data-bs-toggle="modal"
                                  className="box-icon bg_white quick-add tf-btn-loading"
                                  onClick={() => {
                                    addItem(i);
                                    toast.success(
                                      `${t.basket}-${
                                        i.name_show.split(" (")[0]
                                      } ${i.value}`,
                                      {
                                        className: "py-3 px-2",
                                        duration: 1000,
                                      }
                                    );
                                  }}
                                >
                                  <span className="icon icon-bag" />
                                  <span className="tooltip">{t.quickadd}</span>
                                </a>
                                <a
                                  data-bs-toggle="modal"
                                  className="box-icon bg_white quickview tf-btn-loading"
                                  onClick={() => handleModalDesc(i)}
                                >
                                  <span className="icon icon-view" />
                                  <span className="tooltip">{t.quickview}</span>
                                </a>
                              </div>
                            </div>
                            <div className="card-product-info">
                              <NavLink
                                to={`/collections/${slugify(
                                  `${i.name_show}-${i.memory + "GB"}-${i.value}`
                                ).toLowerCase()}`}
                                className="title link aboutDesc"
                              >
                                {i.name_show.split(" (")[0]} {i.value}
                              </NavLink>
                              <div className="d-flex">
                                <span className="price aboutDesc">
                                  {i.price} ₼{" "}
                                </span>
                                <span
                                  className="price ms-3 aboutDesc"
                                  style={{
                                    color: "grey",
                                    fontWeight: "normal",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {i.taksit} ₼{" "}
                                </span>
                              </div>
                              <Toaster position="top-right" />
                              <ul className="list-color-product d-flex justify-content-between align-items-center">
                                <button
                                  onClick={() => {
                                    addItem(i);
                                    toast.success(
                                      `${t.basket}-${
                                        i.name_show.split(" (")[0]
                                      } ${i.value}`,
                                      {
                                        className: "py-3 px-2",
                                        duration: 1000,
                                      }
                                    );
                                  }}
                                  className="tf-btn btn-fill justify-content-center  fs-16  animate-hover-btn "
                                >
                                  <span className="aboutDesc">
                                    {t.addtocart}
                                  </span>
                                </button>
                                <li className="list-color-item color-swatch active">
                                  <span
                                    className="swatch-value bg_orange-3"
                                    style={{ background: `#${i.color_code}` }}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {/* pagination */}
                  <div
                    className={`react_pagination d-flex justify-content-center align-items-center mt-5 ${
                      currentPageData.length === 0 ? "d-none" : ""
                    }`}
                  >
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredData.length / ITEMS_PER_PAGE
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            className={`modal fade modalDemo ${modalDesc ? "show" : ""} `}
            id="quick_view"
            style={{
              display: `${modalDesc ? "block" : "none"}`,
              background: `${modalDesc ? "rgb(0 0 0 / 54%)" : ""}`,
              cursor: "default",
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="header">
                  <span
                    className="icon-close icon-close-popup"
                    data-bs-dismiss="modal"
                    onClick={() => setModalDesc(false)}
                  />
                </div>
                {selectedData !== null && (
                  <div className="wrap">
                    <div className="tf-product-media-wrap">
                      <div className="swiper tf-single-slide">
                        <div className="swiper-wrapper">
                          {images.length > 0 && (
                            <div className="image-container">
                              <LazyLoad>
                                <img
                                  src={images[currentImageIndex]?.value}
                                  alt={`Product ${currentImageIndex}`}
                                  className="aboutDesc"
                                />
                              </LazyLoad>
                            </div>
                          )}
                        </div>
                        <div
                          className="swiper-button-next button-style-arrow single-slide-prev"
                          onClick={goToNext}
                        />
                        <div
                          className="swiper-button-prev button-style-arrow single-slide-next"
                          onClick={goToPrevious}
                        />
                      </div>
                    </div>
                    <div className="tf-product-info-wrap position-relative">
                      <div className="tf-product-info-list">
                        <div className="tf-product-info-title">
                          <h5>
                            <NavLink
                              to={`/collections/${slugify(
                                `${selectedData && selectedData.name_show}-${
                                  selectedData && selectedData.memory + "GB"
                                }-${selectedData && selectedData.value}`
                              ).toLowerCase()}`}
                              className="link aboutDesc"
                            >
                              {selectedData &&
                                selectedData.name_show &&
                                selectedData.name_show.split(" (")[0]}{" "}
                              {selectedData.value}
                            </NavLink>
                          </h5>
                        </div>
                        <div className="tf-product-info-badges">
                          <div className="product-status-content">
                            <i
                              className="icon-lightning"
                              style={{ color: "green" }}
                            />
                            <p className="fw-6 aboutDesc">
                              {language == "az"
                                ? selectedData.rest_az
                                : selectedData.rest_ru}
                            </p>
                          </div>
                        </div>
                        <div className="tf-product-info-price">
                          <div className="price aboutDesc">
                            {selectedData.price} ₼{" "}
                          </div>
                          <div
                            className="price aboutDesc"
                            style={{
                              textDecoration: "line-through",
                              color: "grey",
                            }}
                          >
                            {selectedData.taksit} ₼{" "}
                          </div>
                        </div>
                        <div className="tf-product-description">
                          <p className="aboutDesc"  dangerouslySetInnerHTML={{__html : sentencesHtml}}/>
                        </div>
                        <div className="tf-product-info-variant-picker">
                          <div className="variant-picker-item">
                            <div className="variant-picker-label aboutDesc">
                              {t.color}:
                            </div>
                            <div className="variant-picker-values">
                              <input
                                id="values-orange-1"
                                type="radio"
                                name="color-1"
                                defaultChecked
                              />
                              <label
                                className="hover-tooltip radius-60"
                                htmlFor="values-orange-1"
                                data-value="Orange"
                              >
                                <span
                                  className="btn-checkbox bg-color-orange"
                                  style={{
                                    background: `#${selectedData.color_code}`,
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="tf-product-info-quantity">
                            <div className="quantity-title fw-6 aboutDesc">
                              {t.quantity} :
                            </div>
                            <div className="wg-quantity">
                              <span
                                className="btn-quantity minus-btn aboutDesc"
                                onClick={decreaseQuantity}
                              >
                                -
                              </span>
                              <input
                                type="text"
                                className="aboutDesc"
                                name="number"
                                value={quantity}
                              />
                              <span
                                className="btn-quantity plus-btn aboutDesc"
                                onClick={increaseQuantity}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <div className="tf-product-info-buy-button mt-4">
                            <a className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn ">
                              <span
                                onClick={() => {
                                  addItem(selectedData, quantity);
                                  toast.success(
                                    `${t.basket}-${
                                      selectedData.name_show.split(" (")[0]
                                    } ${selectedData.value}`,
                                    {
                                      className: "py-3 px-2",
                                      duration: 1000,
                                    }
                                  );
                                }}
                                className="aboutDesc"
                              >
                                {t.addtocart}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Shop;
