import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../components/home/Home'
import HeaderTop from '../components/HeaderTop'
import Footer from '../components/Footer'
import Contact from '../pages/Contact'
import Shop from '../pages/Shop'
import ShopDetails from '../pages/ShopDetails'
import Checkout from '../pages/cart/Checkout'
import ScrollToTop from 'react-scroll-to-top'
import About from '../pages/About'
import PrivacePolicy from '../pages/PrivacePolicy'

const AppRouter = () => {
  return (
   <>
   <BrowserRouter>
   <HeaderTop/>
   <Routes>
    <Route path='/' element={<Home/>}></Route>
    <Route path='/contact' element={<Contact/>}></Route>
    <Route path='/collections' element={<Shop/>}></Route>
    <Route path='/checkout' element={<Checkout/>}></Route>
    <Route path='/about' element={<About/>}></Route>
    <Route path='/:parentId' element={<Shop/>}></Route>
    <Route path='/terms-of-use' element={<PrivacePolicy/>}></Route>
    <Route path='/collections/:shopTitle' element={<ShopDetails/>}></Route>
   </Routes>
   <Footer/>
   <ScrollToTop smooth className='scrollToTop'  component={<img src="https://cdn.files-text.com/api/accounts/avatars/a889826e-e4c7-4141-9f5a-b39ba220e8ac/f58c2771-6251-4e07-b63b-e51945756b2a/63566847-f4a1-4086-8247-82c068ffd0bd.png" style={{background:"#1A1A1A",borderRadius:"50%"}} className='img-fluid'></img>}/>
   </BrowserRouter>
   
   </>
  )
}

export default AppRouter
