import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import translations from "../../data/langData";
import { LanguageContext } from "../../context/LanguageContext";
import Swal from "sweetalert2";
const Checkout = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [radio, setRadio] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");

  // online - radio
  const [payment, setPayment] = useState("");

  // ERR MESSAGE
  const [nameErr, setNameErr] = useState(false);
  const [lastErr, setLastErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  // ID PRICE NAME
  const [ids, setIds] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productName, setProductName] = useState([]);

  const productLocal = JSON.parse(localStorage.getItem("cart")) || [];
  const totalPrice = productLocal.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  const handleRadioPayment = (e) => {
    setPayment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    const fieldsCheck = [
      { value: firstName, error: setNameErr },
      { value: lastName, error: setLastErr },
      { value: phone, error: setPhoneErr },
      { value: email, error: setEmailErr },
    ];

    fieldsCheck.forEach((item) => {
      if (item.value.trim() === "") {
        item.error(true);
        isValid = false;
      } else {
        item.error(false);
      }
    });

    if (isValid) {
      const formData = new FormData();
      formData.append("id", JSON.stringify(ids));
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("mobile", phone);
      formData.append("email", email);
      formData.append("store", radio); // 2 radioda burdadi store - address
      formData.append("payment", payment); // 2 radioda burdadi store - address
      formData.append("adress", address);
      formData.append("note", note);
      formData.append("price", JSON.stringify(prices));
      formData.append("productName", JSON.stringify(productName));
      formData.append("payment", payment);

      axios
        .post("https://vertuaz.com/api/order.php", formData)
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: `${t.alert}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRadio("");
          setAddress("");
          setNote("");
          setIds("");
          setPayment("");
          localStorage.removeItem("cart");
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const expandedIds = productLocal.flatMap((item) =>
      Array(item.quantity).fill(item.id)
    );
    setIds(expandedIds);
    const prices = productLocal.map((i) => i.price);
    setPrices(prices);
    const names = productLocal.map((i) => i.name_show);
    setProductName(names);
  }, []);

  console.log(JSON.stringify(productName));

  return (
    <>
      {productLocal.length == 0 ? (
        <h1
          style={{
            fontSize: "25px",
            height: "160px",
            textAlign: "center",
            marginTop: "100px",
          }}
          className="aboutDesc"
        >
          {t.notcheckout}
        </h1>
      ) : (
        <div className="container py-5">
          <div className="content-wraper">
            <div className="container">
              <div className="checkout-details-wrapper">
                <form
                  action={
                    payment === "payment"
                      ? "https://vertuaz.com/payment.php"
                      : undefined
                  }
                  method={payment === "payment" ? "POST" : undefined}
                  onSubmit={payment === "payment" ? undefined : handleSubmit}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-details-wrap">
                        <h3 className="shoping-checkboxt-title aboutDesc">
                          {t.billing}
                        </h3>
                        <div className="row">
                          {payment == "payment" && (
                            <>
                              <input
                                type="hidden"
                                name="price"
                                id="price"
                                value={JSON.stringify(prices)}
                              />
                              <input
                                type="hidden"
                                name="productName"
                                id="price"
                                value={JSON.stringify(productName)}
                              />
                              <input
                                type="hidden"
                                name="id"
                                id="price"
                                value={JSON.stringify(ids)}
                              />
                              <input
                                type="hidden"
                                name="orderTotal"
                                id="price"
                                value={totalPrice.toFixed(1)}
                              />
                            </>
                          )}

                          <div className="col-lg-6">
                            <p className="single-form-row">
                              <label className="aboutDesc">
                                {t.name}{" "}
                                <span className="required aboutDesc">*</span>
                              </label>
                              <input
                                type="text"
                                className="aboutDesc"
                                value={firstName}
                                name="firstName"
                                placeholder={t.name}
                                required
                                style={{
                                  border: `1px solid ${nameErr ? "red" : ""}`,
                                }}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setNameErr(false);
                                }}
                              />
                              {nameErr && (
                                <span className="invalid_message aboutDesc">
                                  {t.invalid}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p className="single-form-row">
                              <label className="aboutDesc">
                                {t.last}
                                <span className="required aboutDesc">*</span>
                              </label>
                              <input
                                className="aboutDesc"
                                type="text"
                                value={lastName}
                                name="lastName"
                                placeholder={t.last}
                                required
                                style={{
                                  border: `1px solid ${lastErr ? "red" : ""}`,
                                }}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setLastErr(false);
                                }}
                              />
                              {lastErr && (
                                <span className="invalid_message aboutDesc">
                                  {t.invalid}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-lg-12">
                            <p className="single-form-row">
                              <label className="aboutDesc">
                                {t.phone}
                                <span className="required aboutDesc">*</span>
                              </label>

                              <input
                                className="aboutDesc"
                                type="text"
                                name="mobile"
                                value={phone}
                                placeholder="+994"
                                required
                                style={{
                                  border: `1px solid ${phoneErr ? "red" : ""}`,
                                }}
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setPhoneErr(false);
                                }}
                              />
                              {phoneErr && (
                                <span className="invalid_message aboutDesc">
                                  {t.invalid}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-lg-12">
                            <div className="single-form-row">
                              <label className="aboutDesc">
                                {t.email}{" "}
                                <span className="required aboutDesc">*</span>
                              </label>
                              <input
                                className="aboutDesc"
                                type="email"
                                name="email"
                                value={email}
                                placeholder={t.email}
                                required
                                style={{
                                  border: `1px solid ${emailErr ? "red" : ""}`,
                                }}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setEmailErr(false);
                                }}
                              />
                              {emailErr && (
                                <span className="invalid_message aboutDesc">
                                  {t.invalid}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mt-1">
                            <label className="radiotxt aboutDesc">
                              {t.radio}
                            </label>
                            <br />
                            <div className="d-flex justify-content-start align-items-center mt-2">
                              <input
                                id="store-radio"
                                type="radio"
                                name="store"
                                onChange={handleRadioChange}
                                value="store"
                                className="radiotxt aboutDesc"
                                checked={radio === "store"}
                                style={{ "accent-color": "#000" }}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="store-radio"
                                className="ms-2 aboutDesc"
                              >
                                {t.radio1}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <div className="d-flex justify-content-start align-items-center">
                              <input
                                id="store-address"
                                type="radio"
                                name="address"
                                className="radiotxt aboutDesc"
                                onChange={handleRadioChange}
                                value="address"
                                checked={radio === "address"}
                                style={{ "accent-color": "#000" }}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="store-address"
                                className="ms-2 aboutDesc"
                              >
                                {t.radio2}
                              </label>
                            </div>
                          </div>
                          {radio == "address" && (
                            <div className="col-lg-12 mt-3">
                              <div className="single-form-row">
                                <label className="aboutDesc">
                                  {t.address}
                                  <span className="required aboutDesc">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="aboutDesc"
                                  value={address}
                                  name="address"
                                  placeholder={t.address1}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-lg-12 mt-3">
                            <p className="single-form-row m-0">
                              <label className="aboutDesc">{t.orderNote}</label>
                              <textarea
                                placeholder={t.orderDesc}
                                className="checkout-mess aboutDesc"
                                name="note"
                                rows={2}
                                cols={5}
                                required
                                value={note}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="your-order-wrapper">
                        <h3 className="shoping-checkboxt-title aboutDesc">
                          {t.orders}
                        </h3>

                        <div className="your-order-wrap">
                          <div className="your-order-table table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th className="product-name aboutDesc">
                                    {t.product}
                                  </th>
                                  <td className="product-total aboutDesc">
                                    {t.total}
                                  </td>
                                </tr>
                              </thead>
                              {productLocal.map((item) => (
                                <tbody>
                                  <tr className="cart_item">
                                    <td className="product-name aboutDesc">
                                      {item.name_show} {item.memory + "GB"}{" "}
                                      {item.value}{" "}
                                      <strong className="product-quantity aboutDesc">
                                        {" "}
                                        × {item.quantity}
                                      </strong>
                                    </td>
                                    <td className="product-total">
                                      <span className="amount aboutDesc">
                                        {item.price} ₼
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                              <tfoot>
                                <tr className="order-total">
                                  <td className="aboutDesc">{t.orderTotal}</td>
                                  <td>
                                    <strong>
                                      <span className="amount aboutDesc">
                                        {totalPrice.toFixed(1)} ₼
                                      </span>
                                    </strong>
                                  </td>
                                </tr>
                                <br />
                                <tr className="order-total">
                                  <h6 className="fs-6">Ödəmə üsulunu seçin</h6>
                                </tr>
                                <tr>
                                  <input
                                    type="radio"
                                    id="cash"
                                    name="cash"
                                    value="cash"
                                    checked={payment == "cash"}
                                    onChange={handleRadioPayment}
                                  />
                                  <label htmlFor="cash">
                                    Qapıda ödəmə üsulu
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="online"
                                    value="payment"
                                    name="payment"
                                    checked={payment == "payment"}
                                    onChange={handleRadioPayment}
                                  />
                                  <label htmlFor="online">
                                    Onlayn Ödəmə üsulu
                                  </label>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div className="payment-method">
                            <div className="order-button-payment">
                              <input
                                type="submit"
                                value={t.placeOrder}
                                className="aboutDesc"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
