import React, { useContext, useEffect, useState } from "react";
import translations from "../../data/langData";
import { LanguageContext } from "../../context/LanguageContext";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { addItem } from "../../utils/addtocart";
import slugify from "slugify";
import LazyLoad from "react-lazy-load";

const Products = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [data, setData] = useState([]);
  const [modalDesc, setModalDesc] = useState(false);
  const [grid, setGrid] = useState("4");
  useEffect(() => {
    Promise.all([axios.get("https://myshops.az/apk/app_prod.php?q=vertu")])
      .then(([dataRes]) => {
        const data = dataRes.data;
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setGrid(1);
      } else {
        setGrid(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section className="flat-spacing-6">
        <div className="container">
          <div className="flat-title mb_1 gap-14">
            <span className="title wow fadeInUp" data-wow-delay="0s">
              {t.collections}
            </span>
          </div>
          <Toaster position="top-right" />
          <div className="grid-layout loadmore-item" data-grid={`grid-${grid}`}>
            {data.slice(1, 9).map((i,index) => (
              <div className="card-product style-4 fl-item" key={index}>
                <div className="card-product-wrapper">
                  <NavLink
                    to={`/collections/${slugify(
                      `${i.name_show}-${i.memory + "GB"}-${i.value}`
                    ).toLowerCase()}`}
                    className="product-img"
                  >
                    <LazyLoad>
                    <img
                      className="lazyload img-product aboutDesc"
                      data-src={i.icon}
                      src={i.icon}
                      alt="image-product"
                    />
                    </LazyLoad>
                    <LazyLoad>
                    <img
                      className="lazyload img-hover aboutDesc"
                      data-src={i.icon}
                      src={i.icon}
                      alt="image-product"
                    />
                    </LazyLoad>
                  </NavLink>
                  <div className="list-product-btn column-right">
                    <a
                      href="javascript:void(0);"
                      className="box-icon wishlist bg_white round btn-icon-action"
                      onClick={() => {
                        addItem(i);
                        toast.success(
                          `${t.basket}-${i.name_show.split(" (")[0]} ${
                            i.value
                          }`,
                          {
                            className: "py-3 px-2",
                            duration: 1000,
                          }
                        );
                      }}
                    >
                      <span className="icon icon-bag" />
                      <span className="tooltip">{t.quickadd}</span>
                      <span className="icon icon-delete" />
                    </a>
                    {/* <a
                      href="#quick_view"
                      data-bs-toggle="modal"
                      className="box-icon bg_white quickview tf-btn-loading"
                      onClick={() => handleModalDesc(i)}
                    >
                      <span className="icon icon-view" />
                      <span className="tooltip">{t.quickview}</span>
                    </a> */}
                  </div>
                  <button
                    style={{ border: "none" }}
                    onClick={() => {
                      addItem(i);
                      toast.success(
                        `${t.basket}-${i.name_show.split(" (")[0]} ${i.value}`,
                        {
                          className: "py-3 px-2",
                          duration: 1000,
                        }
                      );
                    }}
                    data-bs-toggle="modal"
                    className="btn-quick-add quick-add"
                  >
                    {t.addtocart}
                  </button>
                </div>
                <div className="card-product-info">
                  <NavLink
                    to={`/collections/${slugify(
                      `${i.name_show}-${i.memory + "GB"}-${i.value}`
                    ).toLowerCase()}`}
                    className="title link aboutDesc"
                  >
                    {i.name_show.split(" (")[0]}
                  </NavLink>
                  <span className="price aboutDesc">{i.price} ₼</span>
                </div>
              </div>
            ))}
          </div>
          <div className="tf-pagination-wrap view-more-button text-center">
            <NavLink
              to="/collections"
              onClick={() => window.scrollTo({ top: 0 })}
            >
              <button className="tf-btn-loading tf-loading-default style-2 btn-loadmore ">
                <span className="text">{t.readmore}</span>
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      <div
        className={`modal fade modalDemo ${modalDesc ? "show" : ""} `}
        id="quick_view"
        style={{
          display: `${modalDesc ? "block" : "none"}`,
          background: `${modalDesc ? "rgb(0 0 0 / 54%)" : ""}`,
          cursor: "default",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="header">
              <span
                className="icon-close icon-close-popup"
                data-bs-dismiss="modal"
                onClick={() => setModalDesc(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
